<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <div v-show="visiblePrompt">
        <br />
        <base-prompt :prompt="createPrompt()"></base-prompt>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>

      <expansion-panels-layout :inset="true" v-show="visibleOptions">
        <!-- header -->
        <template v-slot:header>
          Insert Options &emsp;
          <v-chip-group column show-arrows>
            <v-chip :small="visibleOnSmAndDown">{{
              insertOptionsTitle()
            }}</v-chip>
          </v-chip-group>
        </template>
        <template v-slot:content>
          <radio-group
            v-if="visibleOptions"
            @change="onCheckInOptionChange"
            :options="checkInFileOptions"
            :select-option="defaultCheckInFileOption"
          >
          </radio-group>
        </template>
      </expansion-panels-layout>

      <expansion-panels-layout class="mt-3" :inset="true">
        <!-- header -->
        <template v-slot:header>
          Upload Options &emsp;
          <core-chip-group
            v-show="hasUploadOptions"
            :items="uploadOptionsList"
            :column="true"
            :chip-small="visibleOnSmAndDown"
            :show-arrows="true"
          >
          </core-chip-group>
        </template>
        <template v-slot:content>
          <check-in-option
            class="mt-4"
            :check-in-option="checkInOption"
          ></check-in-option>
        </template>
      </expansion-panels-layout>

      <expansion-panels-layout
        class="mt-3"
        :inset="true"
        :open="true"
        v-show="canPreview"
      >
        <!-- header -->
        <template v-slot:header>
          Image Preview
        </template>
        <template v-slot:content>
          <v-img :src="previewUrl" />
        </template>
      </expansion-panels-layout>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :color="promptColorClass">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import { findImageOperation, imageOperation } from "@/model/image/imageModel";

// services
import { fullRecordName } from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { actionResultType } from "@/model/action/actionModel";
import { fileCheckInMixin } from "@/mixins/shared/file/fileCheckInMixin";
import { findCheckinFileOption } from "@/model/record/fileModel";
import { createCheckInOption } from "@/model/record/checkInModel";

export default {
  name: "UploadLocalFileDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin, fileCheckInMixin],
  components: {
    RadioGroup: () => import("@/components/shared/core/radio/RadioGroup"),
    ExpansionPanelsLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelsLayout"),
    CheckInOption: () =>
      import("@/components/shared/core/checkIn/CheckInOption"),
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup")
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    destinationRecord: undefined,
    localFile: undefined,
    uploadEvent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      previewUrl: undefined,
      canPreview: false,
      currentSelectedOption: undefined,
      checkInOption: createCheckInOption()
    };
  },

  computed: {
    record() {
      return this.destinationRecord ?? undefined;
    },
    /**
     * Upload Local File Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findImageOperation(imageOperation.Import);
    },

    /**
     * Upload Local File operation Label
     * @return {string} download operation Label
     */
    operationLabel() {
      return "Upload";
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Upload Local File Dialog title
     * @return {string} Move Page Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} File`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} File ${this.localFile?.name} to
       '${fullRecordName(this.destinationRecord)}'`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Prompt text
     * remarks: overwrite promptAbleMixin.promptText
     * @return {string}
     */
    promptText() {
      return `Do you want to ${this.action}?`;
    },

    /**
     * current Prompt Type
     * remarks:  overwrite promptAbleMixin.currentPromptType
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.success;
    },

    /**
     * Prompt Hint
     * remarks:  overwrite promptAbleMixin.promptHint
     * @return {string} Prompt Hint
     */
    promptHint() {
      return `${this.action} in current Image`;
    },

    /**
     * determine whether Options should be visible
     * @return {boolean}
     */
    visibleOptions() {
      return (this.checkInFileOptions?.length ?? 0) > 0;
    }
  },

  methods: {
    /**
     * Performs Upload
     */
    async onOk() {
      try {
        this.clearAlert();
        this.currentSelectedOption = this.checkInFileOption;
        const result = await this.uploadEvent(
          this.isInsertFile,
          this.isInsertFileAtBeginning,
          this.checkInOption
        );
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
        this.defaultCheckInFileOption = this.currentSelectedOption;
      }
    },

    /**
     * Set Preview Image url
     */
    setImageUrl() {
      try {
        if (this.localFile) {
          if (
            !this.localFile.type.startsWith("image/") ||
            this.localFile.type.startsWith("image/tif")
          ) {
            this.canPreview = false;
            this.previewUrl = undefined;
            return;
          }

          const reader = new FileReader();
          reader.onload = event => {
            this.canPreview = true;
            this.previewUrl = event.target.result;
          };
          reader.readAsDataURL(this.localFile);
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Get Insert Option Title
     * @return {String|string}
     */
    insertOptionsTitle() {
      return findCheckinFileOption(this.checkInFileOption).text;
    }
  },

  watch: {
    checkInOption: {
      handler() {
        this.updateSelectedOptionList();
      },
      deep: true
    }
  },

  updated() {
    //Update image url when dialog is opened
    this.setImageUrl();
  }
};
</script>
