import { notImplementedMethod } from "@/services/error/errorMessages";
import {
  canInsertImageFile,
  canOverwriteFileVersion,
  fullRecordName,
  getRecordTypeName,
  isCompoundFile
} from "@/services/record/recordService";

// model
import {
  checkInFileOptions,
  checkInFileOption,
  checkInWIPFileOptions,
  insertFileOption
} from "@/model/record/fileModel";
import { recordOperation, recordType } from "@/model/record/recordModel";
import { UploadOptionModel } from "@/model/record/checkInModel";

export const fileCheckInMixin = {
  data() {
    return {
      uploadFileInput: undefined,
      uploadFileError: undefined,
      checkInFileOption: checkInFileOption.insertAfterLastPage,
      visibleUploadFilePopoverMenu: false,
      compoundFileOverwrite: -2,
      uploadOptionsList: []
    };
  },
  components: {
    CheckInFile: () => import("@/components/file/CheckInFile"),
    FileInput: () => import("@/components/shared/core/inputs/FileInput"),
    RadioGroup: () => import("@/components/shared/core/radio/RadioGroup")
  },
  computed: {
    /**
     * Current selected record.
     *  Abstract computed
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[] }}
     */
    record: () => undefined,

    /**
     * Get Check In File Options
     * @return {{text: *, value: *}[]|{text: *, value: *}[]}
     */
    checkInFileOptions() {
      console.log(this.record);
      return this.visibleCheckInFileOptions
        ? this.isViewerCheckInAction
          ? checkInWIPFileOptions.map(el => {
              return {
                value: el.value,
                text: el.text,
                disabled: this.enabledCheckInOption(el)
              };
            })
          : checkInFileOptions.map(el => {
              return {
                value: el.value,
                text: el.text,
                disabled: this.enabledCheckInOption(el)
              };
            })
        : [];
    },

    /**
     * upload File Progress value
     * @return {number}
     */
    uploadFileProgress() {
      return 0;
    },

    /**
     * upload File Progress Text
     * @return {string}
     */
    uploadFileProgressText() {
      return this.formatUploadFileProgressText(this.uploadFileProgress);
    },

    /**
     * upload File Menu Label
     * @return {string}
     */
    uploadFileMenuLabel() {
      return "Upload File ...";
    },

    /**
     * upload File Popover Dialog Title
     * @return {string} upload File Popover Dialog Title
     */
    uploadFilePopoverDialogTitle() {
      return "Upload File";
    },

    /**
     * compute upload File Popover Dialog Subtitle
     * @return {string} upload File Popover Dialog Subtitle
     */
    uploadFilePopoverDialogSubtitle() {
      return this.record
        ? `To ${getRecordTypeName(this.record)}: ${fullRecordName(
            this.record
          ) ?? "?"}`
        : "?";
    },

    /**
     * command Close Upload File Dialog Tooltip
     * @return {string}
     */
    commandCloseUploadFileDialogTooltip() {
      return "Close Upload File Dialog";
    },

    /**
     * upload File Command Label
     * @return {string}
     */
    uploadFileCommandLabel() {
      return "Upload";
    },

    /**
     * check In File Menu Label
     * @return {string}
     */
    checkInFileMenuLabel() {
      return "Check In ...";
    },

    /**
     * check In File Popover Dialog Title
     * @return {string}
     */
    checkInFilePopoverDialogTitle() {
      return "Check In";
    },

    /**
     * upload File Command Label
     * @return {string}
     */
    checkInFileCommandLabel() {
      return "Check In";
    },

    /**
     * command Close Check-In File Dialog Tooltip
     * @return {string}
     */
    commandCloseCheckInFileDialogTooltip() {
      return "Close Check In File Dialog";
    },

    /**
     * close Command Label
     * @return {string}
     */
    closeCommandLabel() {
      return "Close";
    },

    /**
     * can Insert File being Check In
     * @return {boolean}
     */
    canInsertFile() {
      return canInsertImageFile(this.record);
    },

    /**
     * can Insert File, being Check In, at the Beginning of the current file version
     * @return {boolean}
     */
    canInsertFileAtBeginning() {
      return canInsertImageFile(this.record);
    },

    /**
     * can Overwrite current File Version when Check In a new file
     * @return {boolean}
     */
    canOverwriteFileVersion() {
      return canOverwriteFileVersion(this.record);
    },

    /**
     * is Insert File into current file version
     * @return {boolean}
     */
    isInsertFile() {
      return this.checkInFileOption !== checkInFileOption.overwrite;
    },

    /**
     * is Insert File Before First Page
     * @return {boolean}
     */
    isInsertFileAtBeginning() {
      return this.checkInFileOption === checkInFileOption.insertBeforeFirstPage;
    },

    /**
     * determines visibility of Check In File Options
     * @return {boolean}
     */
    visibleCheckInFileOptions() {
      return this.isViewer
        ? (this.record?.recordTypeId ?? recordType.RECORD) ===
            recordType.FILE ||
            (this.record?.recordTypeId ?? recordType.RECORD) ===
              recordType.RECORD ||
            (this.record?.recordTypeId ?? recordType.RECORD) ===
              recordType.DOCUMENT
        : (this.record?.recordTypeId ?? recordType.RECORD) ===
            recordType.FILE && !isCompoundFile(this.record);
    },

    /**
     * Compute whether Is Valid Upload File Operation
     * @return {boolean} returns true if Is Valid Upload File Operation
     */
    enabledCommandUploadFile() {
      return !!(
        this.record &&
        this.isValidOperation(recordOperation.Update) &&
        ((this.record?.recordTypeId ?? -1) === recordType.RECORD ||
          (this.record?.recordTypeId ?? -1) === recordType.FILE ||
          (this.record?.recordTypeId ?? -1) === recordType.DOCUMENT ||
          (this.record?.recordTypeId ?? -1) === recordType.FOLDER ||
          (this.record?.recordTypeId ?? -1) === recordType.ITEM)
      );
    },

    /**
     * Default Check In File Option
     * @return {number}
     */
    defaultCheckInFileOption: {
      get() {
        console.log("IS VIEWER", this.isViewer);
        return (this.checkInFileOption =
          this.canInsertFile || this.isViewer
            ? checkInFileOption.insertAfterLastPage
            : checkInFileOption.overwrite);
      },
      set(value) {
        console.log("Value", value);
        this.checkInFileOption = value;
      }
    },

    /**
     * compute Upload File Error
     * @return {string|null|undefined}
     */
    computedUploadFileError() {
      return this.uploadFileError;
    },

    /**
     * Verify if Upload Option List has data
     * @return {boolean}
     */
    hasUploadOptions() {
      return (this.uploadOptionsList?.length ?? 0) > 0;
    }
  },
  methods: {
    /**
     * Abstract set Upload File progress
     * @param {Number|number} progress
     */
    setUploadFileProgress(progress) {
      console.warn(
        `Not implemented setUploadFileProgress() progress:`,
        progress
      );
    },

    /**
     * Abstract upload File
     * @param {{file: File, id: (number|number), uploadFileModel: {Extension: (string|undefined), Draft: boolean, InsertFileOption: {afterLastPage: number, beforeFirstPage: number, overwrite: number}, Comments: string, Enqueue: boolean}}} payload
     * @return {Promise<void>}
     */
    async uploadFile(payload) {
      console.warn(`Not implemented uploadFile() payload:`, payload);
    },

    /**
     * Abstract upload Compound Document File
     * @param {{id: Number, file: any, insertBeforePage: Number, enqueue: Boolean, comments: string}} payload
     * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
     */
    async uploadCompoundDocumentFile(payload) {
      throw notImplementedMethod(
        `uploadCompoundDocumentFile() payload: ${payload}`
      );
    },

    /**
     * Abstract Check In File
     * @param {{file: File, id: (number|number), uploadFileModel: {Extension: (string|undefined), Draft: boolean, InsertFileOption: {afterLastPage: number, beforeFirstPage: number, overwrite: number}, Comments: string, Enqueue: boolean}}} payload
     */
    checkInFile(payload) {
      throw notImplementedMethod(`checkInFile() payload: ${payload}`);
    },

    /**
     * On File Input Change Event handler
     * @param file
     */
    onFileInputChange(file) {
      try {
        this.uploadFileInput = file;
        this.setUploadFileProgress(0);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * On Check In File Option Change Event handler
     * @param {Number|number} option
     */
    onCheckInOptionChange(option) {
      try {
        if (this.checkInFileOptions.find(el => el.value === option)) {
          this.checkInFileOption = option;
          console.log(
            `onCheckInOptionChange() this.checkInFileSelectOption:`,
            this.checkInFileOption
          );
        } else {
          console.warn(
            `Couldn't change Check In File Option: ${option}. Provided Not valid Check File In Option.`
          );
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * format Upload File Progress Text
     * @param {Number|number} progress
     * @return {string}
     */
    formatUploadFileProgressText(progress) {
      return `${progress} %`;
    },

    /**
     * Overwrite is Valid Check In File Option
     * @param {{value:number, text: string}} option
     */
    isValidCheckInFileOption(option) {
      switch (option?.value) {
        case checkInFileOption.insertBeforeFirstPage: {
          return this.canInsertFileAtBeginning;
        }
        case checkInFileOption.insertAfterLastPage: {
          return this.canInsertFileAtBeginning;
        }
        case checkInFileOption.overwrite: {
          return this.canOverwriteFileVersion;
        }
        default: {
          return false;
        }
      }
    },

    /**
     * Performs uploadFile/checkInFile
     * @param {File} file
     * @param {Boolean} isInsertFile
     * @param {Boolean} insertAtBeginning
     * @param  {{isCheckInDraft: boolean, isCheckInOCR : boolean, checkInComment : string}} checkInOption
     * @return {Promise<void>}
     */
    async performCheckIn(file, isInsertFile, insertAtBeginning, checkInOption) {
      if (!this.record) {
        throw `Cannot Check In File. There is no Current Record`;
      }

      console.log(`performCheckIn() file to:`, fullRecordName(this.record));

      const insertOption = isInsertFile
        ? insertAtBeginning
          ? insertFileOption.beforeFirstPage
          : insertFileOption.afterLastPage
        : insertFileOption.overwrite;

      const model = UploadOptionModel(
        checkInOption.checkInComment,
        checkInOption.isCheckInOCR,
        checkInOption.isCheckInDraft,
        insertOption
      );

      switch (this.record.recordTypeId) {
        case recordType.RECORD: {
          await this.uploadFile({
            id: this.record?.id ?? -1,
            file: file,
            uploadFileModel: model
          });
          break;
        }
        case recordType.FILE: {
          await this.checkInFile({
            id: this.record?.id ?? -1,
            file: file,
            uploadFileModel: model
          });
          break;
        }
        case recordType.DOCUMENT: {
          //
          // TODO: provide all UI entries for:
          //  - insertBeforePage
          //  - enqueue   (canOcr)
          //  - comments
          //
          await this.uploadCompoundDocumentFile({
            id: this.record?.id ?? -1,
            file: file,
            insertBeforePage: -1,
            enqueue: true,
            comments: "Upload Compound Document File"
          });
          break;
        }
        default: {
          const msg = `Not supported upload file to: ${this.record.recordType}.`;
          console.log(msg);
          throw msg;
        }
      }
    },
    enabledCheckInOption(option) {
      return this.isViewer ? false : !this.isValidCheckInFileOption(option);
    },

    /**
     * Update Upload Option list with selected Option
     * @return {string}
     */
    updateSelectedOptionList() {
      this.uploadOptionsList = [];
      if (this.checkInOption.checkInComment)
        this.uploadOptionsList.push("Comment");
      if (this.checkInOption.isCheckInDraft)
        this.uploadOptionsList.push("Draft");
    }
  },
  watch: {
    visibleUploadFilePopoverMenu() {
      //
      // Re-set UploadFile related variables
      //
      this.uploadFileInput = undefined;
      this.uploadFileError = undefined;
      this.setUploadFileProgress(0);
    }
  }
};
